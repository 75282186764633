import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

const About = ({ data }) => {
  const StyledAboutUs = styled.div`
    .top-bg {
      height: 30rem;
      position: relative;
      h1 {
        position: absolute;
        bottom: -5rem;
        left: 50%;
        margin-bottom: 0;
        transform: translate(-50%, -50%);
        color: #ffffff;
        background: ${props => props.theme.colors.org};
        padding: 1.5rem 2.5rem;
        font-size: 2.5rem;
      }
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    .container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 8rem auto;
    }
    .content {
      width: 45%;
      font-family: ${props => props.theme.fonts.sec};
      p {
        font-size: 1.8rem;
        line-height: 2.5rem;
      }
      ul li {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        color: #333333;
      }
      h1, h2, h3, h4, h5, h6 {
        color: ${props => props.theme.colors.org};
      }
      h1 {
        font-size: 2.5rem;
      }
      h2 {
        font-size: 2rem;
      }
      h3 {
        font-size: 1.9rem;
      }
      h4 {
        font-size: 1.8rem;
      }
      h5 {
        font-size: 1.7rem;
      }
      h6 {
        font-size: 1.6rem;
      }
    }
    .photo {
      width: 50%;
      height: 75rem;
      border-bottom: 1rem solid ${props => props.theme.colors.org};
      border-right: 1rem solid ${props => props.theme.colors.org};
      .gatsby-image-wrapper {
        height: 100%;
      }
    }
    @media (max-width: 768px) {
      .container {
        flex-direction: column-reverse;
        .photo,
        .content {
          width: 100%;
        }
        .photo {
          height: 25rem;
          margin-bottom: 3rem;
        }
      }
    }
  `;
  return (
    <Layout>
      <SEO title="About Us" />
      <StyledAboutUs>
        <div className="top-bg">
          <Img fluid={data.wordpressWpAboutUsContent.acf.top_background.localFile.childImageSharp.fluid} />
          <h1>ABOUT US</h1>
        </div>
        <div className="container">
          <div className="content" dangerouslySetInnerHTML={{ __html: data.wordpressWpAboutUsContent.acf.page_content }} />
          <div className="photo">
            <Img fluid={data.wordpressWpAboutUsContent.acf.right_side_photo.localFile.childImageSharp.fluid} />
          </div>
        </div>
      </StyledAboutUs>
    </Layout>
  );
};

export const data = graphql`
  {
    wordpressWpAboutUsContent {
      acf {
        top_background {
          localFile {
            childImageSharp {
              fluid(maxWidth: 2560) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        right_side_photo {
          localFile {
            childImageSharp {
              fluid(maxWidth: 900) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        page_content
      }
    }
  }
`;

export default About;
